<template lang="pug">
  .table-layout
    table.table.table--cell-overflow-hidden
      thead.table__header
        tr.table__row.table__row--header
          th.table__cell {{ 'pages.storage.provider_name' | translate }} / {{ 'pages.storage.buyer_name' | translate }}
          th.table__cell {{ 'base.provider' | translate }} / {{ 'base.buyer' | translate }}
          th.table__cell {{ 'base.info' | translate }}
      v-table-body(
        :cols="3"
        :loading="loading"
        :empty="!providers.length")
        suppliers-table-row(
          v-for="provider in providers"
          :key="provider.id"
          :provider="provider")
      tfoot.table__footer(
        v-if="!loading && showPagination")
        tr.table__row.table__row--footer
          td.table__cell(colspan="5")
            .table__pagination
              v-paginate(
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageChangeHandler")

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import paginationMixin from '@/mixins/pagination.mixin'
  import SuppliersTableRow from './SuppliersTableRow'

  export default {
    name: 'SuppliersTable',

    mixins: [paginationMixin],

    components: {
      SuppliersTableRow
    },

    mounted () {
      this.setupPagination(this.fetchProviders)
    },

    methods: {
      ...mapActions(['fetchProviders'])
    },

    computed: {
      ...mapGetters([
        'providers'
      ])
    }
  }
</script>

<style lang="scss" scoped>
</style>
